<template>
    <div class="main">
        <!-- 头部 -->
        <div class="head">
            <van-image height="25vh" src="http://huidazhe.vip/static/img/top.b3d9413.png" fit="contain"/>
        </div>
        <div class="content" v-if="!hasBought">
            <!-- 兑换码输入框 -->
            <van-radio-group v-model="checked" checked-color="#17161c" icon-size="24px">
              <van-cell-group inset>
                <van-cell title="嗨金卡特权" clickable @click="checked = '1'">
                  <template #right-icon>
                    <van-radio name="1" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-radio-group>
            <!-- 查看兑换权益按钮 -->
            <van-button
                class="content__rights-no-btn"
                block
                round
                type="primary"
                color="#f0d4c0"
                @click="buy9()"
                :loading="loading"
            >
                连续包年9.9元
            </van-button>
        </div>
        <div v-else class="content">
          <van-cell title="特价电影" is-link url="http://huidazhe.vip/#/independentFilm?distributorCode=1484928&flag=independent" />
          <van-cell title="优惠快餐饮品" is-link url="http://huidazhe.vip/#/user/orderCollection?distributorCode=1484928" />
        </div>
    </div> 
</template>

<script>
import {Button, Image, Row, Col, Field, RadioGroup, Radio, CellGroup, Cell} from "vant";
import request from "@/utils/request.js";
// import {navMixins, wechatMixins} from "@/mixins.js";
// import wechat from "@/utils/wechat.js"

export default {
    name: "Ticket",
    // mixins: [navMixins, wechatMixins],
    components: {
        [Button.name]: Button,
        [Image.name]: Image,
        [Row.name]: Row,
        [Col.name]: Col,
        [Field.name]: Field,
        [Radio.name]: Radio,
        [RadioGroup.name]: RadioGroup,
        [CellGroup.name]: CellGroup,
        [Cell.name]: Cell
    },
    data() {
        return {
            checked: '1',
            hasBought: false
        };
    },
    mounted() {
      this.fetchSkuOrder();
    },
    methods: {
      async fetchSkuOrder() {
          try {
            const {data: result} = await request.post(`/customer/order/searchOrder`, {
              data: {
                skuCode: '99'
              }
            });
            const hasBought = !!result.data && result.data.length > 0;
            if (hasBought) {
              this.hasBought = hasBought;
            }
          } catch(err) {
            this.hasBought = false;
          }
      },
      async buy9() {
        console.log("9.9");
        //  const orderResult = await request.post('/customer/order/createOrder', {
        //     data: {
        //         orderPrice: 99,
        //         itemList: [{
        //             skuCode: '99',
        //             count: 1,
        //             skuPrice: 99,
        //         }]
        //     }
        // });
        // 创建支付单
        // const orderNo = orderResult.data.orderNo;

        // const {data} = await request.post('/customer/payment/createPayOrder', {
        //     data: {
        //         orderNo,
        //         payType: "WECHAT_JSAPI"
        //     }
        // });
        // if (this.orderPrice != 0) {
        //     // const paymentParam = JSON.parse(data.paymentParam);
        //     // 拉起Wap支付
        //     // wechat.triggerWapPayment(paymentParam.appId,
        //     //                         paymentParam.timeStamp,
        //     //                         paymentParam.nonceStr,
        //     //                         paymentParam.package,
        //     //                         paymentParam.signType,
        //     //                         paymentParam.paySign,
        //     //                         () => {
        //     //                             this.fetchSkuOrder();
        //     //                         });
        // } else {
        // }
      }
    }
}
</script>

<style lang="less">
@color-vice: #f0d4c0;
@color-vice-font: #ffdfc3;
@color-vice-weight: #4d3933;

@color-theme: #17161c;
@color-border:#00000026;
@font-size-title: 16px;
@font-weight-title: 500;
@line-height-title: 24px;
@font-color-title: #000000d9;

@font-size-normal: 14px;
@font-weight-normal: 400;
@line-height-normal: 22px;
@font-color-normal: #000000a6;

.main {
  color: @color-theme!important;
  font-size: 24px!important;
  font-weight: 700!important;
}

.head {
    background-color: @color-vice;
    text-align: center;
}

.content {
    height: 75vh;
    background-color: rgb(253, 245, 234);
    padding: 24px 32px 0 32px;
}

.content__title {
  font-size: 18px;
  color: @color-vice;
  background-color: @color-theme;
  padding: 4px;
}

.content__rights-no-field {
    border-radius: 8px;
}

.content__rights-no-btn.van-button--primary {
    margin-top: 24px;
    font-weight: 700;
    border: 1px solid @color-border;
    color: @color-theme!important;
}

.content__step {
    margin-top: 24px;
    border: 1px solid @color-vice-weight;
    padding: 16px 8px;
    border-radius: 8px;
}

.content__step-item {
    margin: 24px 0;
}

.content__step-title {
    color: @color-theme;
    font-weight: 200!important;
    font-size: @font-size-normal;
    padding: 1px 0;
    border-radius: 4px;
    line-height: 14px;
}
.content__step-info {
    padding-left: 16px;
    color: @color-theme;
    font-size: 12px;
}

</style>